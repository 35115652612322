import { Float32BufferAttribute, Vector3 } from 'three'
import * as THREE from 'three'

export class UvMapManager {
    constructor (geometry, fullHeight = 1, printHeight = 1, perimeter = 1, topOffset = 0, uvmapMode = 'full auto') {
        this.fullHeight = fullHeight
        this.printHeight = printHeight
        this.perimeter = perimeter
        this.topOffset = topOffset
        this.uvmapMode = uvmapMode
        this.geometry = geometry
    }

    loadUVMap () {
        if (typeof this.ratio === 'undefined') {
           this.ratio = this.perimeter / this.printHeight
        }
        if (typeof this.topOffset === 'undefined') {
            this.topOffset = 0 // (this.fullHeight - this.printHeight)
        }

        let arrayUv = []
        if (this.uvmapMode === 'correct' || this.uvmapMode === 'full auto') {
            arrayUv = this.generateUvArray()

            if (this.uvmapMode === 'correct') {
                const uvObj = this.geometry.attributes.uv.array
                for (let f = 0; f < arrayUv.length; f += 2) {
                    if (uvObj[f] < -2) {
                    arrayUv[f] = 0
                    arrayUv[f + 1] = 0
                    }
                }
            }
            const uv = new Float32BufferAttribute(arrayUv, 2)
            this.geometry.setAttribute('uv', uv)
        }
        return arrayUv
    }

    generateUvArray () {
        const allPosition = this.geometry.attributes.position.array
        const allNormal = this.geometry.attributes.normal.array
        const findMax = a => a.reduce((res, cur) => res < cur ? cur : res, -Infinity)
        const isFaceOutward = (faceNormal, facePosition) => {
            return faceNormal.dot(facePosition) >= -0.3
        }
        const getNthArray = (inArr, n) => {
            const outArr = []
            for (let i = n; i < inArr.length; i += 3) {
                outArr.push(inArr[i])
            }
            return outArr
        }
        const height = findMax(getNthArray(allPosition, 1))

        const arrayUv = []
        const ratio = this.ratio
        const fullHeight = this.fullHeight
        const printHeight = this.printHeight
        const scaleV = ratio * (printHeight / fullHeight) // between 0 and 1
        let offsetV = -((fullHeight - printHeight) / 2 - this.topOffset) // offset from center
        offsetV = offsetV / fullHeight / scaleV // between 0 and 1

        // A face own 3 positions. A position own 3 axis position. So a face own 9 numbers
        // One loop by face
        for (let i = 0; i < allNormal.length; i += 9) {
            const norm = new Vector3(allNormal[i], allNormal[i + 1], allNormal[i + 2])
            const facePos = new Vector3(allPosition[i], allPosition[i + 1], allPosition[i + 2])
            if (isFaceOutward(norm, facePos)) {
                // a loop by position
                for (let j = 0; j < 9; j += 3) {
                    const pos = new Vector3(allPosition[i + j], allPosition[i + j + 1], allPosition[i + j + 2])
                    const pos2D = new THREE.Vector2(pos.x, pos.z)
                    const center = new THREE.Vector2(0, 0)
                    let angle = Math.atan2((pos2D.x - center.x), (pos2D.y - center.y)) - Math.PI // rad
                    angle = angle * 180 / Math.PI // rad to pi
                    angle += 360 // dont know why, but all angles are here negative

                    let u = angle / 360
                    // u = 0.5 // quarter rotate
                    u = u % 1 // valid uvmap is always between 0 and 1. Circular mapping

                    let v = pos.y / height // full height mapping, on square gaba
                    v = v / scaleV // stretch vertical
                    v = v + (0.5 - 0.5 / scaleV) // center uvmap
                    v += offsetV // offset from middle
                    arrayUv.push(u, v)
                }

                // fix edge wrapping
                const l = i / 3 * 2
                const uList = [parseFloat(arrayUv[l]), parseFloat(arrayUv[l + 2]), parseFloat(arrayUv[l + 4])]
                for (let u1 = 0; u1 < uList.length; u1++) {
                    for (let u2 = 0; u2 < uList.length; u2++) {
                        if ((uList[u1] - uList[u2]) < (-0.8)) {
                            uList[u1] += 1
                        }
                    }
                }
                [arrayUv[l], arrayUv[l + 2], arrayUv[l + 4]] = uList
            } else {
                arrayUv.push(0, 0, 0, 0, 0, 0)
            }
        }

        return arrayUv
    }

    get ratio () {
        return this.perimeter / this.printHeight
    }
}
